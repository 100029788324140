import React from 'react'
import cx from 'classnames'
import { Container } from 'react-bootstrap'
import { HeaderContent } from '~components/Header/HeaderContent'
import logo from '~img/logo-footer.svg'
import * as css from './style.module.scss'

const Footer = ({ siteTitle }) => {
  return (
    <Container as="footer" className={css.container}>
      <div className={css.root}>
        <HeaderContent className={css.desktop} />
        <div className={cx(css.mobile, css.logo)}>
          <img src={logo} width="90" height="63" alt="SolRocket logo" />
        </div>
        <div className={css.footer}>
          <ul className={css.links}>
            <li>
              <a href="/documents/privacy-policy.pdf" target="__blank" rel="noopener">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/documents/terms-and-onditions.pdf" target="__blank" rel="noopener">
                Terms and Conditions
              </a>
            </li>
          </ul>
          <p>© 2021 {siteTitle}. All Rights Reserved</p>
        </div>
      </div>
    </Container>
  )
}

export default Footer
