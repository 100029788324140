import React from 'react'
import { Container } from 'react-bootstrap'
import { HeaderContent } from './HeaderContent'
import * as css from './style.module.scss'

const Header = () => (
  <div className={css.root}>
    <Container as="header">
      <HeaderContent />
    </Container>
  </div>
)

export default Header
