import React from 'react'
import { Button } from 'react-bootstrap'
import cx from 'classnames'
import logo from '~img/logo.svg'
import * as css from './HeaderContent.module.scss'

export const HeaderContent = ({ className }) => {
  return (
    <div className={cx(css.header, className)}>
      <div>
        <img src={logo} width="139" height="32" alt="SolRocket logo" />
      </div>
      <nav className={css.nav}>
        <Button href="/#tiers" variant="secondary" size="sm">
          Tier
        </Button>
        <Button href="/#subscribe" variant="secondary" size="sm">
          Subscribe
        </Button>
      </nav>
    </div>
  )
}
